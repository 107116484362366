import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Loading from "./components/shared/Loading/Loading";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import Aos from "aos";
import "aos/dist/aos.css";

import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

const Home = lazy(() => import("./pages/Home/index"));
const Mobile = lazy(() => import("./pages/Mobile/index"));
const Signup = lazy(() => import("./pages/Signup/index"));
const Advertiser = lazy(() => import("./pages/Advertiser/index"));
const Publisher = lazy(() => import("./pages/Publisher/index"));
const Login = lazy(() => import("./pages/Login/index"));
const Resset = lazy(() => import("./pages/Resset/index"));
const CampaignCreation = lazy(() => import("./pages/CampaignCreation/index"));
const Optimization = lazy(() => import("./pages/Optimization/index"));
const Contact = lazy(() => import("./pages/Contact/index"));
const Track = lazy(() => import("./pages/Track/index"));
const SelfServe = lazy(() => import("./pages/SelfServe/index"));
const AdvertiseWithUs = lazy(() => import("./pages/AdvertiseWithUs/index"));
const Pricing = lazy(() => import("./pages/Pricing/index"));
const Exchange = lazy(() => import("./pages/Exchange/index"));
const AdGatePublisher = lazy(() => import("./pages/AdGatePublisher/index"));
const Formats = lazy(() => import("./pages/Formats/index"));
function App() {
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    Aos.init({
      duration: 1250,
      once: true,
    });
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <IntlProvider>
          <ScrollToTop />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/mobile" element={<Mobile />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route path="/signup/advertiser" element={<Advertiser />} />
            <Route path="/signup/publisher" element={<Publisher />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resset-password" element={<Resset />} />

            <Route
              path="/advertiser/campaign-creation"
              element={<CampaignCreation />}
            />
            <Route path="/advertiser/optimization" element={<Optimization />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/advertiser/tracking-reporting" element={<Track />} />
            <Route path="/advertiser/selfe-sterm" element={<SelfServe />} />
            <Route
              path="/advertiser/advertise-with-us"
              element={<AdvertiseWithUs />}
            />

            <Route path="/advertiser/pricing-models" element={<Pricing />} />
            <Route path="/advertiser/adExchange" element={<Exchange />} />
            <Route
              path="/publisher/AdGate-Publisher"
              element={<AdGatePublisher />}
            />
            <Route path="/adFormats" element={<Formats />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </IntlProvider>
      </Suspense>
    </Router>
  );
}

export default App;
